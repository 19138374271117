@font-face {
  font-family: "Rubik Mono One";
  src: local("Rubik Mono One"),
    url(./fonts/Rubik_Mono_One/RubikMonoOne-Regular.ttf) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.App {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: black;
  font-family: "Rubik Mono One", monospace;
  color: beige;
  min-height: 100%;
}

/* Style the video: 100% width and height to cover the entire window */
video.background {
  position: fixed;
  left: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

/* Add some content at the bottom of the video/page */
div.header {
  position: fixed;
  top: 35%;
  width: 100%;
}

div.header,
div.footer {
  color: beige;
  mix-blend-mode: difference;
}

div.header,
div.header *,
div.footer,
div.footer * {
  padding: 0px 0px;
  margin: 0px 0px;
}

div.header h1 {
  font-size: 3.5rem;
}

div.header {
  font-size: 2rem;
}

div.latest-release {
  z-index: 2;
  position: fixed;
  top: 50%;
  width: 70%;
}

div.links {
  font-family: "Courier New", Courier, monospace;
  position: fixed;
  font-size: 1.5rem;
  bottom: 10%;
  color: beige;
  mix-blend-mode: difference;
}

div.links .external-icon {
  color: beige;
  padding: 0px 10px;
}

div.links .external-icon:hover {
  color: white;
  font-size: 2rem;
}

div.footer {
  position: fixed;
  bottom: 5%;
}

div.footer h1 {
  font-size: 0.5rem;
}
